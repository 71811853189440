import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.14_next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom_vz4kykwt6oog4ljtlfiy2km7ba/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.14_next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom_vz4kykwt6oog4ljtlfiy2km7ba/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.14_next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom_vz4kykwt6oog4ljtlfiy2km7ba/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.20.0_next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_rea_c5lywfrx66mjhxyoirdsdrmbia/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.20.0_next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_rea_c5lywfrx66mjhxyoirdsdrmbia/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"outfit\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ArticleContent/BreweryContent/BreweryContent.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ArticleContent/HeadingContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ArticleContent/ReviewContent/ReviewContent.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ArticleContent/SakeContent/SakeContent.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ContactForm/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/LocaleSwitcher/LocaleSwitcherSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Select/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/SignInButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/SignInModal/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/provider/SessionProvider.tsx");
